<template>
  <div class="background">
    <v-container class="spacing-playground pa-lg-10"
      fluid>
      <v-card class="d-flex flex-column"
              style="margin:0 auto;"
              max-width="1200px"      
              :loading="loading">
        <v-card-title>
          <div class="logo">
            <app-logo/>
          </div>
          {{titulo}}
        </v-card-title>
        <v-divider/>
        <div :style="`overflow: auto; max-height: calc(100vh - ${190 + ($vuetify.breakpoint.lgAndUp ? 54 : 0)}px)`">
          <v-card-subtitle>
            {{origemAcesso}}
          </v-card-subtitle>
          <v-divider/>
          <v-card-text class="py-4 flex-grow-1">
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
            <div> teste </div>
          </v-card-text>
        </div>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn outlined color="primary" @click="() => $router.push({name: 'LoginUsuario'})">Cancelar</v-btn>
          <v-btn color="primary">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import AppLogo from "../../components/app/AppLogo";

export default {
  components: {AppLogo},
  name: "CadastreSe",
  props: ['titulo'],
  data() {
    return {
      item: {
        formaPgtoAnuidade: "",
        valorAnuidade: 0,
        descontoAnuidade: 0,
        valAnuidadeComDesconto: 0,
      },
      formValid: false,
      loading: false,
      origemAcesso: "",
    }
  },
  computed:{
  },
  methods: {
    getOrigemAcesso() {
      let ret = "Está acessando via ";
      if (this.$device.isDesktop){
        ret += "Desktop";
      } else if (this.$device.isTablet){
        ret += "Tablet";
      } else {
        ret += "Mobile";
      }
      ret += " e por um ";
      if (window.matchMedia('(display-mode: standalone)').matches) ret += "PWA";
      ret += "browser";

      return ret;
    }    
  },
  mounted() {
    this.origemAcesso = this.getOrigemAcesso();
  }
}
</script>

<style scoped lang="scss">

.background {
  background-image: url('../../assets/backgrounds/bg01.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  flex: 1;

  .logo {
    padding: 5px;

    img {
      height: 60px;
    }
  }

  .box-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }
}

</style>